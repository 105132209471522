$primary-color: #3bafda;

.file-upload-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.file-upload-wrapper .file-upload-label {
  text-align: center;
  cursor: pointer;
}
.file-upload-wrapper .file-upload-label img{
  width: 200px;
}
.file-upload-wrapper .file-upload-label .file-upload-action {
  display: block;
  padding: 0.5rem;
  background-color: #616161;
  color: #fff;
  font-size: 0.5rem;
}
pdf-viewer {
  display: inline-block !important;
  height: 80vh;
  max-width: 100% !important;
  overflow: auto;
}

.ng2-pdf-viewer-container {
  // position: relative !important;
  width: 100%;
  // max-width: 100% !important;
}
.invoice-preview-img {
  text-align: center;
}
.invoice-preview-img img {
  width: 150px;
}
.vertical-center {
  vertical-align: middle !important;
}
.tbl-none-border,
.tbl-none-border thead,
.tbl-none-border th,
.tbl-none-border tr,
.tbl-none-border td,
.tbl-none-border tbody {
  border: 0px !important;
}
.full-width {
  width: 100% !important;
}
.item-bg {
  background-color: #F5F6FA !important;
}
.recon-footer-bg {
  background-color: #FBF3F6;
}
.sm-file-upload .file-upload-wrapper .file-upload-label img{
  width: 125px !important;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.action-icon-zoom {
  font-size: 2rem;
}
#chart {
  // padding: 0;
  // max-width: 100px;
  // margin: 35px auto;
}

#chart .apexcharts-canvas {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 150px !important;
  height: 110px !important;
}
#chart svg {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: -2rem;
}
.chart-description {
font-size: 0.7rem !important;
}
.active-status {
  background-color: $primary-color;
  color: #fff;
  cursor: not-allowed;
  pointer-events: none;
}
.btn-unpaid-invoice {
  border: 1px solid #EEEEEE;
  padding: 0.7rem 0rem;
  font-size: 0.8rem;
  background-color: white;
}
.btn-unpaid-invoice:hover {
  background-color: #EEEEEE;
}
.filter-heading {
  margin-bottom: 0.3rem;
  background-color: #f8f8f8;
  padding: 0.5rem 1.3rem;
  margin-left: -24px;
  margin-right: -24px;
  // font-weight: bold;
}
.text-primary {
  color:  $primary-color !important;
}
.text-bold {
  font-weight: bold !important;
}
.text-documents-summary {
  font-size: 0.8rem;
}
.filter-label {
  font-weight: unset !important;
}
.mlr-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.invoice-payment-action {
  padding: 0.1rem 0rem;
    font-size: 0.8rem;

}
.due-status-overdue {
  background-color: #EF9A9A;
}
.due-status-pending-payment,
.due-status-awaiting,
.mis,
.due-status-in_bank_proc
 {
  background-color: #FFCA28;
}
.due-status-paid,
.due-status-approved {
  background-color: #4CAF50;
  color: #fff;
}
.invoice-card {
  margin-bottom: 1rem;
  padding-top: 0.6rem;
  // border-top: 1px solid #9E9E9E;
}
.invoice-card-item {
  display: flex !important;
  align-items: center;

}
.invoice-card-item label {
  margin-bottom: 0px;
  padding-left: 0.8rem;
  font-weight: 400;
}
.payment-details-title {
  color: $primary-color;
  font-size: 0.8rem;
}
.tab-btns{
  height: 5vh;
  width: 100%;
  display: flex;
  margin-left: -25px;
  margin-top: -25px;
}
.tab-btns button{
  border: none;
  font-size: 15px;
  padding: 0px 30px 0px 30px;
}
.tab-btn-active{
  background-color: #37cde6;
  color: #fff;
}
.tab-btn-inactive{
  background-color: #37cce618;
  color: #37cde6;
}
.apply-credit-note-header {
  background-color: #F6FDFE;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  padding: 0.8rem;
}
.apply-credit-note-header .apply-cn-heading {
  margin-top: 0;
    margin-bottom: 0rem;
    font-size: 0.7rem;
}
.apply-credit-note-header .apply-cn-content {
  margin-top: 0;
    margin-bottom: 0rem;
    font-size: 1.3rem;
}
.apply-cn-table {
  width: 100%;
  margin-top: 1.5rem;
}
.apply-cn-table tr{
  border-bottom: 1px solid;
}
.apply-cn-table td{
  border: 0px transparent;
  padding: 0.35rem 0rem;
}
.btn-ap-green {
  background-color: #b2be00;
  color: #fff;
}
.bg-light-grey {
  background-color: #F2F5F7;
}
.unpaid-invoice-filter-panel-heading {
  background-color: #37CDE6;
  color: #fff;
  margin-top: 0px;
  padding: 0.5rem;
  font-size: 1rem;
}
.invoice-detail .supplier-name {
  font-weight: 600;
  font-size: 1.1rem;
}
.scrollable-wrapper {
  height: 70vh;
  overflow: auto;
}
.checkbox-lg {
  height: 20px;
  width: 20px;
}
.date-toggle-btn {
  border: 0px;
  background-color: #f3f7f9;
}
.num-of-invoices-label {
  padding: 0.3rem;
  border-radius: 2px;
  font-weight: 600;
}
.txn-detail-heading {
  font-size: 1.3rem;
  color: #859aa5;
}
.txn-detail-sub-heading {
  border-bottom: 1px solid;
  margin-bottom: 1rem;
}
.txn-detail-sub-heading p {
  margin-bottom: 0px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #343a40;
}
